<template>
    <v-container class="px-6 pt-0 pb-3" style="max-width: 100%;">
        <v-row class="ma-0 pa-0">
            <v-col cols="10" v-if="!loading">
              <v-chip label class="data_breakdown_chip" v-for="(opt) in optimizations" :key="opt.ID" color="black" :outlined="my_optimization !== opt.ID" @click="my_optimization = opt.ID">
                {{opt.Name}}
              </v-chip>
              <v-menu
                v-model="add_optimization_menu"
                :close-on-content-click="false"
                :nudge-width="200"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: ontooltip }">
                      <v-btn icon elevation="0" v-on="ontooltip">
                        <v-icon color="black" :disabled="optimizations.length > 4 || lock_changes" v-bind="attrs" v-on="on" >mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <TooltipDescription title="Add New Optimization" desc_2=""></TooltipDescription>
                  </v-tooltip>
                </template>
                <v-card>
                  <v-card-title class="optimization_create_title">Create Optimization</v-card-title>
                  <v-card-text class="ma-0 pa-3">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-text-field v-model="new_optimization_title" class="ma-0 pa-0 optimization_calculator_action" label="Optimization Name"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <span class="optimization_calculator_action">Risk Level</span>
                      </v-col>
                      <v-col cols="12" class="ma-0">
                        <v-radio-group v-model="new_optimization_risk" class="ma-0 pa-0 optimization_calculator_action">
                          <v-radio value="low" label="Low Risk"></v-radio>
                          <v-radio value="mid" label="Mid Risk"></v-radio>
                          <v-radio value="high" label="High Risk"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined class="optimization_calculator_action"  @click="new_optimization_title=''; new_optimization_risk='low'; add_optimization_menu = false"> Cancel </v-btn>
                    <v-btn outlined class="optimization_calculator_action" @click="add_optimization_menu = false; add_new_optimization();" :disabled="new_optimization_title === ''"> Create </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
              <v-tooltip top>
                <template v-slot:activator="{ on: ontooltip }">
                  <v-btn icon elevation="0" v-on="ontooltip" @click="copy_optimization" :disabled="optimizations.length > 4 || lock_changes">
                    <v-icon color="black">mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <TooltipDescription title="Copy Current Optimization" desc_2=""></TooltipDescription>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on: ontooltip }">
                  <v-btn icon elevation="0" v-on="ontooltip" @click="delete_optimization" :disabled="optimizations.length < 2 || lock_changes">
                    <v-icon color="black">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <TooltipDescription title="Delete Current Optimization" desc_2=""></TooltipDescription>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on: ontooltip }">
                  <v-btn icon elevation="0" v-on="ontooltip" @click="save_optimization" :disabled="!save_optimizations">
                    <v-icon color="black">mdi-content-save-outline</v-icon>
                  </v-btn>
                </template>
                <TooltipDescription title="Save Optimizations" desc_2=""></TooltipDescription>
              </v-tooltip>
            </v-col>
            <v-col cols="12" v-else>
                <v-row class="ma-0 pa-0" justify="center" :align="'center'" style="height: 100%">
                    <v-col cols="auto" class="ma-0 pa-0">
                    <v-progress-circular
                        size="100"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4" style="height: 62vh" v-if="!loading" class="ma-0 pa-0 pt-3">
                <v-card class="ma-0 pa-3" style="width: 100%; height: 100%; overflow-y: scroll;">
                    <v-row class="ma-0 pa-0" align="center">
                        <v-col cols="9" class="ma-0 pa-1">
                            <v-text-field v-model="my_optimization_name" :disabled="lock_changes" class="optimization_calculator_action" label="Optimization Name" hide-details @change="update_optimization_name"></v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="ma-0 pa-1">
                            <v-menu
                                v-model="optimization_setup_global_menu"
                                :close-on-content-click="false"
                                :nudge-width="200"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: ontooltip }">
                                            <v-btn icon elevation="0" v-on="ontooltip" :disabled="lock_changes">
                                                <v-icon color="black" v-bind="attrs" v-on="on" >mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <TooltipDescription title="Optimization Settings" desc_2=""></TooltipDescription>
                                    </v-tooltip>
                                </template>
                                <v-card width="400px">
                                    <v-card-title class="optimization_create_title">Optimization Settings</v-card-title>
                                    <v-card-text class="ma-0 pa-3">
                                        <v-row class="ma-0 pa-0">
                                            <v-col cols="12" class="ma-0 pa-0">
                                                <span>Optimization Type</span>
                                            </v-col>
                                            <v-col cols="12" class="ma-0 pa-0">
                                                <v-radio-group row class="optimization_calculator_action" v-model="optimization_strategy" :disabled="optimization_channels.length==0 || lock_changes" @change="update_opt_setup_vars">
                                                    <v-radio label="ROAS" value="roas"></v-radio>
                                                    <v-radio label="Attribution" value="attribution"></v-radio>
                                                    <v-radio label="Ladder" value="ladder" disabled></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                            <v-col cols="12" class="ma-0 pa-0">
                                                <span>Total Budget Shifts</span>
                                            </v-col>
                                            <v-col cols="12" class="ma-0 pa-0">
                                                <v-switch v-model="all_locked" label="Shift Total Budget with Investment Changes"></v-switch>
                                            </v-col>
                                            <v-col cols="12" class="ma-0 pa-0">
                                                <span>All Investment Budget Shifts</span>
                                            </v-col>
                                            <v-col cols="6" class="ma-0 pa-3">
                                                <v-text-field v-model="all_min_change" prefix="-" suffix="%" type="number" class="ma-0 pa-0 optimization_calculator_action" hide-details></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="ma-0 pa-3">
                                                <v-text-field v-model="all_max_change" prefix="+" suffix="%" type="number" class="ma-0 pa-0 optimization_calculator_action" hide-details></v-text-field>
                                            </v-col>
                                            <v-col cols="12" class="ma-0 pa-0 pt-6">
                                                <span>Lock All Investments</span>
                                            </v-col>
                                            <v-col cols="auto" class="ma-0 pa-0">
                                                <v-switch v-model="all_locked" prepend-icon="mdi-lock-open" append-icon="mdi-lock">
                                                </v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn outlined class="optimization_calculator_action"  @click="optimization_setup_global_menu = false;"> Cancel </v-btn>
                                        <v-btn outlined class="optimization_calculator_action" @click="set_all_changes();optimization_setup_global_menu = false;"> Save </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-1">
                            <v-text-field v-model="optimization_total_budget" :disabled="lock_changes" class="optimization_calculator_action" label="Total Budget" hide-details type="number" prefix="$" @change="update_opt_setup_vars"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card class="ma-0 mt-3 pa-1" style="width: 100%;" v-for="channel, idx in optimization_channels" :key="idx">
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0">
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="auto" class="ma-0 pa-1">
                                        <v-icon :color="colors[idx]">mdi-circle</v-icon>
                                    </v-col>
                                    <v-col cols="auto" class="ma-0 pa-1">
                                        <span class="optimization_calculator_channel">{{channel['channel']}}</span>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto" class="ma-0 pa-1">
                                        <v-tooltip top v-if="!Object.keys(selected_response_curve_build).includes(channel['channel'])">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color="black" @click="open_response_curve(channel['channel'],idx)" v-on="on">mdi-eye</v-icon>
                                            </template>
                                            <TooltipDescription title="View Investment" desc_2=""></TooltipDescription>
                                        </v-tooltip>
                                        <v-tooltip top v-else>
                                            <template v-slot:activator="{ on }">
                                                <v-icon color="black" @click="close_response_curve(channel['channel'])" v-on="on">mdi-eye-off</v-icon>
                                            </template>
                                            <TooltipDescription title="Hide Investment" desc_2=""></TooltipDescription>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0">
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="5" class="ma-0 pa-1">
                                        <v-text-field prefix="$" v-model="channel['spend']" class="ma-0 pa-0 optimization_calculator_action" label="" hide-details @change="spend_shifted(channel['channel'], idx)" :disabled="lock_changes"></v-text-field>
                                        <!-- <span class="optimization_calculator_spend">
                                            {{
                                                channel['spend'].toLocaleString("en-CA", {
                                                style: "currency",
                                                currency: "CAD",
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })
                                            }}
                                        </span> -->
                                    </v-col>
                                    <v-col cols="2" class="ma-0 pa-1">
                                        <v-text-field prefix="+" suffix="%" v-model="channel['min_change']" class="ma-0 pa-0 optimization_calculator_action" label="" hide-details @change="save_optimizations=true;" :disabled="lock_changes"></v-text-field>
                                        <!-- <span>+{{ channel['min_change'] }}%</span> -->
                                    </v-col>
                                    <v-col cols="2" class="ma-0 pa-1">
                                        <v-text-field prefix="+" suffix="%" v-model="channel['max_change']" class="ma-0 pa-0 optimization_calculator_action" label="" hide-details @change="save_optimizations=true;" :disabled="lock_changes"></v-text-field>
                                        <!-- <span>+{{ channel['min_change'] }}%</span> -->
                                    </v-col>
                                    <!-- <v-col cols="auto" class="ma-0 pa-1">
                                        <span>-{{ channel['max_change'] }}%</span>
                                    </v-col> -->
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto" class="ma-0 pa-1">
                                        <v-tooltip top v-if="channel['lock']">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color="black" @click="channel['lock'] = !channel['lock']; save_optimizations=true;" :disabled="lock_changes" v-on="on">mdi-lock</v-icon>
                                            </template>
                                            <TooltipDescription title="Investment Locked" desc_2=""></TooltipDescription>
                                        </v-tooltip>
                                        <v-tooltip top v-if="!channel['lock']">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color="black" @click="channel['lock'] = !channel['lock']; save_optimizations=true;" :disabled="lock_changes" v-on="on">mdi-lock-open-outline</v-icon>
                                            </template>
                                            <TooltipDescription title="Investment Unlocked" desc_2=""></TooltipDescription>
                                        </v-tooltip>
                                    </v-col>
                                    <!-- <v-col cols="auto" class="ma-0 pa-1">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon color="black" v-on="on" :disabled="lock_changes || true">mdi-cog</v-icon>
                                            </template>
                                            <TooltipDescription title="Investment Optimization Settings" desc_2=""></TooltipDescription>
                                        </v-tooltip>
                                    </v-col> -->
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
            </v-col>
            <v-col cols="8" style="height: 62vh" v-if="!loading" class="ma-0 pa-0 pl-3 pt-3">
                <v-card style="width: 100%; height: 100%">
                    <v-row class="ma-0 pa-0" style="width: 100%; height: 100%">
                        <v-col cols="12" v-if="selected_response_curve.length > 0">
                            <GChart
                                :settings="{ packages: ['corechart', 'table'] }"
                                style="width: 100%; height: 100%"
                                type="LineChart"
                                :data="selected_response_curve"
                                :options="chartOptions"
                                :events="chartEvents"
                                ref="uniqueReachCurve"/>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE } from "@/constants/constants";
  // import DateRange from "@/components/Utility/DateRange.vue";
  // import performance_data_header from "@/input_data/performance_table_header.json";
  // import PredictionPieChart from "@/components/Planning/PlanningSetup/Charts/PredictionPieChart.vue"
  import TooltipDescription from "@/components/Utility/TooltipDescription.vue";
  // import ResponseCurve from "@/components/Planning/PlanningSetup/Charts/ResponseCurve.vue"
  // import PredictionStatistic from "./Charts/PredictionStatistic.vue"

//   import PerformanceMainStatistic from "@/components/Performance/PerformanceStats/PerformanceReportSections/PerformanceMainStatistic.vue";

  // import PlanningOptimizationSetup from "./PlanningOptimizationSetup.vue"
  import { GChart } from "vue-google-charts/legacy";

  import api_caller from "@/javascript/planning.js"

  export default {
    name: "PlanningOptimizationSetup",
    props: [
      'data_version',
      'data_levels',
      'project_date_range',
      'lock_changes',
      'project_plan_id'
    ],
    components: {
      TooltipDescription,
    //   PerformanceMainStatistic,
      GChart
    },
    data: () => ({
        loading: true,

        add_optimization_menu: false,
        new_optimization_title: '',
        new_optimization_risk: 'low',
        save_optimizations: false,

        optimizations: [],
        optimization_channels: [],
        my_optimization: null,
        my_optimization_name: '',
        performanceData: null,
        optimization_setups: {},

        optimization_total_budget: null,
        optimization_total_budget_pm: null,
        optimization_strategy: null,
        optimization_calculator: null,
        
        optimization_setup_global_menu: false,

        response_curve_optimization: false,
        response_curves: {},
        selected_response_curve: [],
        selected_response_curve_build: {},
        selected_response_curve_channel: '',
        selected_response_curve_spend: 0,
        selected_response_curve_volume: 0,
        selected_response_curve_roas: 0,
        selected_response_curve_shift: false,

        colors: [
            '#0079c1', '#AD343E', '#F2AF29', '#7D3AC1', '#628B48', '#FF3CC7', '#00E5E8', // Original colors
            '#FF5733', '#4A90E2', '#50B74A', '#F5A623', '#D0021B', '#9013FE', '#B8E986', // Additional colors
            '#4A4A4A', '#F8E71C', '#8B572A', '#417505', '#BD10E0', '#F9A8E3', '#001F3F', // Additional colors
            '#FF851B', '#85144B', '#39CCCC', '#3D9970', '#FFDC00', '#B10DC9', '#FF4136', // Additional colors
            '#2ECC40', '#FF69B4', '#1F618D' // Final set to reach 30 colors
        ],
        chartOptions: {
            titleTextStyle: {fontName: 'Poppins', fontSize: 18, bold: false},
            colors: [],
            vAxes: { 0:{title: 'Volume ($)', titleTextStyle:{fontName: 'Poppins', fontSize: 16, italic: false}, textStyle:{fontName: 'Poppins', fontSize: 12}}},
            vAxis: { viewWindowMode: 'explicit', baselineColor: '#000000', viewWindow: {min: 0}, gridlines:{count: 0}, minorGridlines:{count: 0}, format:'short'},
            hAxis: {viewWindowMode: 'explicit', viewWindow:{min: 0}, title: 'Budget ($)', minValue: 0, format:'short', slantedText: false, slantedTextAngle: -15, baselineColor: '#000000', textStyle:{fontName: 'Poppins', fontSize: 12}, titleTextStyle:{fontName: 'Poppins', fontSize: 16, italic: false}, gridlines:{count: 0}, minorGridlines:{count: 0}},
            curveType: 'function',
            legend: { position: 'none', maxLines: 4},
            chartArea: {left: '10%', top: '2%', width: '87%', height: '90%'},
            pointSize: 5,
            backgroundColor: 'none',
            fontName: 'Poppins'
        },

        chartEvents: {},

        all_min_change: 0,
        all_max_change: 10,
        all_locked: false,
        hold_total_budget: true
    }),
    mounted(){
        this.reset_table();
        this.chartEvents = {
            select: (event) => {
                this.handleChartSelect(event);
            }
        };
    },
    computed: {
    },
    watch: {
        project_plan_id: function(){
            this.reset_table();
        },
        my_optimization: function(){
            if(this.my_optimization in this.optimization_setups){
                let opt_setup = this.optimization_setups[this.my_optimization];

                this.optimization_total_budget = opt_setup['total_budget'];
                this.optimization_total_budget_pm = opt_setup['total_budget_shift'];
                this.optimization_strategy = opt_setup['strategy'];
                this.optimization_calculator = opt_setup['calculator'];
                this.optimization_channels = opt_setup['optimization'];
                this.my_optimization_name = opt_setup['title'];

                let opt_channels = [];
                // let c_idxs = [];
                for(let chnl of Object.keys(this.selected_response_curve_build)){
                    opt_channels.push([chnl, ((this.selected_response_curve_build[chnl][0] - 1) / 2)]);
                }
                for(let chnl of opt_channels){
                    this.close_response_curve(chnl[0]);
                }
                if(opt_channels.length == 0 && this.optimization_channels.length > 0){
                    opt_channels.push([this.optimization_channels[0]['channel'], 0]);
                }
                
                for(let chnl of opt_channels){
                    this.open_response_curve(chnl[0], chnl[1]);
                }
            }
      }
    },
    methods: {
        update_optimization_name: function(){
            this.optimization_setups[this.my_optimization]['title'] = this.my_optimization_name;
            for(let idx = 0 ; idx < this.optimizations.length; idx ++){
                if(this.optimizations[idx]['ID'] == this.my_optimization){
                    this.optimizations[idx]['Name'] = this.my_optimization_name;
                }
            }
            this.save_optimizations = true;
        },
        set_all_changes(){
            let min_change = this.all_min_change;
            let max_change = this.all_max_change;
            let locked = this.all_locked;

            for(let opt of this.optimization_setups[this.my_optimization]['optimization']){
                opt['min_change'] = min_change;
                opt['max_change'] = max_change;
                opt['lock'] = locked;
            }

            this.save_optimizations=true;
        },
        update_opt_setup_vars: function(){
            this.optimization_setups[this.my_optimization]['total_budget'] = this.optimization_total_budget;
            this.optimization_setups[this.my_optimization]['total_budget_shift'] = this.optimization_total_budget_pm;
            this.optimization_setups[this.my_optimization]['strategy'] = this.optimization_strategy;
            this.optimization_setups[this.my_optimization]['calculator'] = this.optimization_calculator;
            this.save_optimizations=true;
        },
        reset_table: async function(){
            this.loading = true;
            let token = await this.$auth.getTokenSilently();
            let payload = {
                'project_id': this.$project.get_val(),
                'project_plan_id': this.project_plan_id.toString()
            }
            let data = await api_caller.get_optimization_info(token,payload).then((resp)=>{
                                    if(!resp['success']){
                                        return false
                                    }
                                    return resp['data']
                                    }).catch((e)=>{
                                        console.log(e)
                                        return 'False'
                                    });
            if(data){
                this.response_curves = data['response_curves'];
                this.assign_optimization(data);
            }else {
                this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
            }
        },
        assign_optimization: function(data){
            if(data){
                this.performanceData = data;
                let opt_setups = this.performanceData['setups'];
                this.optimization_setups = {}
                for(let setup of opt_setups){
                    this.optimization_setups[setup['id']] = setup
                }

                this.optimizations = this.performanceData['optimizations'];
                this.my_optimization = null
                setTimeout( () => this.my_optimization = this.optimizations[0]['ID'], 100)

                this.loading = false;
            }
            else{
                this.performanceData = null;
                this.loading = true;
            }
        },
        save_optimization: async function(reload_data=true){
            let optimizations = [];
            for(let id of Object.keys(this.optimization_setups)){
                optimizations.push(this.optimization_setups[id]);
            }
            this.loading = true;
            let token = await this.$auth.getTokenSilently();
            let payload = {
                'project_id': this.$project.get_val(),
                'project_plan_id': this.project_plan_id.toString(),
                'optimizations': optimizations
            }
            let data = await api_caller.update_optimization(token, payload).then((resp)=>{
                                    if(!resp['success']){
                                        return false
                                    }
                                    return resp['data']
                                    }).catch((e)=>{
                                        console.log(e)
                                        return false;
                                    });
            if(data){
                this.save_optimizations=false;
                if(reload_data){
                    this.assign_optimization(data);
                    this.loading=false
                }
            }else {
                this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
            }
        },
        add_new_optimization: async function(){
            this.loading = true;
            if(this.save_optimizations){
                await this.save_optimization(false)
            }
            let token = await this.$auth.getTokenSilently();
            let payload = {
                'project_id': this.$project.get_val(),
                'project_plan_id': this.project_plan_id.toString(),
                'optimization_title': this.new_optimization_title,
                'optimization_risk': this.new_optimization_risk
            }
            let data = await api_caller.add_new_optimization(token,payload).then((resp)=>{
                                    if(!resp['success']){
                                        return false
                                    }
                                    return resp['data']
                                    }).catch((e)=>{
                                        console.log(e)
                                        return 'False'
                                    });
            if(data){
                this.assign_optimization(data);
                this.new_optimization_title = '';
                this.new_optimization_risk = 'low';
            }else {
                this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
            }
        },
        copy_optimization: async function() {
            this.loading = true;
            if(this.save_optimizations){
                await this.save_optimization(false)
            }
            let token = await this.$auth.getTokenSilently();
            let payload = {
                'project_id': this.$project.get_val(),
                'project_plan_id': this.project_plan_id.toString(),
                'optimization_id': this.my_optimization
            }
            let data = await api_caller.copy_optimization(token,payload).then((resp)=>{
                                    if(!resp['success']){
                                        return false
                                    }
                                    return resp['data']
                                    }).catch((e)=>{
                                        console.log(e)
                                        return 'False'
                                    });
            if(data){
                this.assign_optimization(data);
            }else {
                this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
            }
        },
        delete_optimization: async function(){
            this.loading = true;
            if(this.save_optimizations){
                await this.save_optimization(false)
            }
            let token = await this.$auth.getTokenSilently();
            let payload = {
                'project_id': this.$project.get_val(),
                'project_plan_id': this.project_plan_id.toString(),
                'optimization_id': this.my_optimization
            }
            let data = await api_caller.delete_optimization(token,payload).then((resp)=>{
                                    if(!resp['success']){
                                        return false
                                    }
                                    return resp['data']
                                    }).catch((e)=>{
                                        console.log(e)
                                        return false;
                                    });
            if(data){
                this.assign_optimization(data);
            }else {
                this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
            }
        },
        spend_shifted: function(channel, channel_idx){
            if(Object.keys(this.selected_response_curve_build).includes(channel)){
                this.close_response_curve(channel);
                this.open_response_curve(channel, channel_idx);
            }
            this.save_optimizations = true;
        },
        open_response_curve: function(channel, color_idx){
            let channel_spend = 0;
            for(let opt of this.optimization_setups[this.my_optimization]['optimization']){
                if(opt['channel'] == channel){
                    channel_spend = opt['spend'];
                }
            }

            let cur_channel_length = Object.keys(this.selected_response_curve_build).length;
            // if(!Object.keys(this.selected_response_curve_build).includes(channel)){

            // }

            let new_resp_curve = []
            let cur_idx = 0;
            let curve_spend = 0;
            let curve_vol = 0;
            let continue_running = false;

            let tmp_row = [];
            if(!Object.keys(this.response_curves).includes(channel) || this.response_curves[channel] == null){
                this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
                return;
            }
            for(let data_row of this.response_curves[channel]){
                tmp_row = [];
                if(cur_idx == 0){
                    // new_resp_curve.push([data_row[ 0],data_row[1], "point"]);
                    tmp_row.push(data_row[0])
                    for(let fill = 1; fill < (1+(cur_channel_length*2)); fill += 2){
                        tmp_row.push(this.selected_response_curve[0][fill]);
                        tmp_row.push("Point");
                    }
                    tmp_row.push(channel);
                    tmp_row.push("Point " + channel);
                    new_resp_curve.push(tmp_row);
                }
                else{
                    tmp_row.push(data_row[0]);
                    for(let fill = 0; fill < cur_channel_length; fill ++){
                        tmp_row.push(null);
                        tmp_row.push(null);
                    }
                    tmp_row.push(data_row[1]);
                    tmp_row.push(null);
                    new_resp_curve.push(tmp_row);
                    // new_resp_curve.push([data_row[0],data_row[1],null]);
                    
                    if(data_row[0] > channel_spend && !continue_running){
                        if(Math.abs(data_row[0] - channel_spend) > Math.abs(this.response_curves[channel][cur_idx][0] - channel_spend)){
                            curve_spend = this.response_curves[channel][cur_idx][0];
                            curve_vol = this.response_curves[channel][cur_idx][1];
                        }
                        else{
                            curve_spend = this.response_curves[channel][cur_idx - 1][0];
                            curve_vol = this.response_curves[channel][cur_idx - 1][1];
                        }
                        continue_running = true;
                    }
                }
                cur_idx += 1;
            }

            tmp_row = [curve_spend];
            for(let fill = 0; fill < cur_channel_length; fill ++){
                tmp_row.push(null);
                tmp_row.push(null);
            }
            tmp_row.push(null);
            tmp_row.push(0);
            new_resp_curve.push(tmp_row);

            tmp_row = [curve_spend];
            for(let fill = 0; fill < cur_channel_length; fill ++){
                tmp_row.push(null);
                tmp_row.push(null);
            }
            tmp_row.push(null);
            tmp_row.push(curve_vol);
            new_resp_curve.push(tmp_row);

            cur_idx = 0;
            for(let row of this.selected_response_curve){
                if(cur_idx == 0){
                    cur_idx += 1;
                    continue;
                }else{
                    cur_idx += 1;
                    row.push(null);
                    row.push(null);
                    new_resp_curve.push(row);
                }
            }

            let colors = this.chartOptions['colors'];
            colors.push(this.colors[color_idx]);
            colors.push(this.colors[color_idx]);
            this.chartOptions['colors'] = colors;
            
            this.selected_response_curve_build[channel] = [(1 + (cur_channel_length*2)),(2 + (cur_channel_length*2))];

            this.selected_response_curve = new_resp_curve;
            this.response_curve_optimization = true;
            this.selected_response_curve_channel = channel;
            this.selected_response_curve_spend = curve_spend;
            this.selected_response_curve_volume = curve_vol;
            this.selected_response_curve_roas = curve_vol / curve_spend;
        },
        close_response_curve: function(channel){
            let new_resp_curve = [];
            let remove_idx = this.selected_response_curve_build[channel];

            let tmp_row = [];
            let all_null = false;
            for(let row of this.selected_response_curve){
                tmp_row = [];
                all_null = true;

                for(let jdx=0; jdx < row.length; jdx++){
                    if(!remove_idx.includes(jdx)){
                        tmp_row.push(row[jdx]);
                        if(jdx > 0 && row[jdx] != null){
                            all_null = false;
                        }
                    }
                }

                if(!all_null){
                    new_resp_curve.push(tmp_row);
                }
            }

            let colors = this.chartOptions['colors'];
            let new_colors = [];
            for(let cdx = 0; cdx < colors.length; cdx ++){
                if(!remove_idx.includes((cdx + 1))){
                    new_colors.push(colors[cdx]);
                }
            }
            this.chartOptions['colors'] = new_colors;

            delete this.selected_response_curve_build[channel];

            for(let channel of Object.keys(this.selected_response_curve_build)){
                if(this.selected_response_curve_build[channel][0] > remove_idx[0]){
                    this.selected_response_curve_build[channel][0] -= 2;
                    this.selected_response_curve_build[channel][1] -= 2;
                }
            }

            this.selected_response_curve = new_resp_curve;
        },
        handleChartSelect() {
            this.$nextTick(() => {
                const table = this.$refs.uniqueReachCurve.chartObject;
                const data = this.$refs.uniqueReachCurve.data;
                const selection = table.getSelection()[0];
                if (selection != null && !this.lock_changes) {
                    const row = selection.row;
                    const channel_spend = data[row][0];

                    let channel_idx = 0;
                    for(let jdx = 1; jdx < data[row].length; jdx++){
                        if(data[row][jdx] != null){
                            channel_idx = jdx;
                            break;
                        }
                    }

                    let selected_channel = '';
                    for(let chnl of Object.keys(this.selected_response_curve_build)){
                        if(this.selected_response_curve_build[chnl].includes(channel_idx)){
                            selected_channel = chnl;
                        }
                    }
                    
                    let chnl_opt_idx = 0;
                    for(let opt of this.optimization_setups[this.my_optimization]['optimization']){
                        if(opt['channel'] == selected_channel){
                            this.optimization_setups[this.my_optimization]['optimization'][chnl_opt_idx]['spend'] = channel_spend;
                            break;
                        }
                        chnl_opt_idx ++;
                    } 

                    this.save_optimizations = true;
                    this.close_response_curve(selected_channel);
                    this.open_response_curve(selected_channel, chnl_opt_idx);
                }
            });
        },
    }
}
</script>

<style scoped>
.optimization_response_curve_title {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
    font-size: 1.1em;
  }
  .data_breakdown_chip {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
    font-size: 0.85em;
    width: 150px;
  }
  .optimization_setup_title {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
    font-size: 0.85em;
  }
  .optimization_create_title {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
  }
  .optimization_calculator_channel {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
  }
  .optimization_calculator_spend {
    font-family: 'Poppins' !important;
    font-weight: 400 ;
  }
  .optimization_calculator_action {
    font-family: 'Poppins' !important;
    font-weight: 400 ;
  }
</style>