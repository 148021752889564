import { render, staticRenderFns } from "./SalesOverviewStatistic.vue?vue&type=template&id=65ddb892&scoped=true"
import script from "./SalesOverviewStatistic.vue?vue&type=script&lang=js"
export * from "./SalesOverviewStatistic.vue?vue&type=script&lang=js"
import style0 from "./SalesOverviewStatistic.vue?vue&type=style&index=0&id=65ddb892&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65ddb892",
  null
  
)

export default component.exports