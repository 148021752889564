<template>
    <v-container class="px-6 pt-0 pb-3" style="max-width: 100%;">
      <v-row class="ma-0 pa-0" style="width: 100%; height: 100%" align="center" justify="center" v-if="data_loading">
        <v-col cols="auto">
          <v-progress-circular indeterminate color="primary" size="100"></v-progress-circular>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" v-if="!data_loading" justify="space-around">
        <v-col cols="12" class="ma-0 pa-0">
          <v-chip label class="data_breakdown_chip" color="black" :outlined="my_optimization !== 'overview'" @click="my_optimization = 'overview'">
            Overview
          </v-chip>
          <v-chip v-for="opt in optimizations" label class="data_breakdown_chip" color="black" :key="opt.idx" :outlined="my_optimization !== opt" @click="my_optimization = opt">
            {{opt}}
          </v-chip>
        </v-col>
        <v-col cols="8" v-if="my_optimization !== 'overview'" class="px-1">
          <v-row class="ma-0 pa-0">
            <v-col cols="4" class="ma-0 pa-0">
              <PredictionStatistic statistic_title_name="Spend" :value="optimization_data[my_optimization]['spend']" :change="optimization_data[my_optimization]['spend_change']"></PredictionStatistic>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
              <PredictionStatistic statistic_title_name="Volume" :value="optimization_data[my_optimization]['volume']" :change="optimization_data[my_optimization]['volume_change']"></PredictionStatistic>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
              <PredictionStatistic statistic_title_name="ROAS" :value="optimization_data[my_optimization]['roas']" :change="optimization_data[my_optimization]['roas_change']"></PredictionStatistic>
            </v-col>
          </v-row>
          <DataLevelTable
              :tableHeader="optimization_data[my_optimization]['tbl_header']"
              :tableData="optimization_data[my_optimization]['tbl_data']"
              :selectedLevels="['channel']"
              :tableRowClickFunction='empty_table_click'
              :numericColumns="numeric_cols"
              :currencyColumns='currency_cols'
              :percentageColumns="change_cols"
              :mid="true"
            />
        </v-col>
        <v-col cols="4" v-if="my_optimization !== 'overview'">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 pa-0">
              <PredictionPieChart :value="optimization_data[my_optimization]['pie_data']" statistic_title_name="Spend"></PredictionPieChart>
            </v-col>
            <!-- <v-col cols="12" class="ma-0 pa-0">
              <PredictionRadarChartOverview></PredictionRadarChartOverview>
            </v-col> -->
          </v-row>
        </v-col>
        <v-col cols="12" class="py-0 pt-3"  v-if="my_optimization === 'overview'">
          <PredictionBarChartOverview :value="prediction_overview_data"></PredictionBarChartOverview>
        </v-col>
        <v-col cols="12" class="py-0" v-if="my_optimization === 'overview'">
          <DataLevelTable
              :tableHeader="optimization_header"
              :tableData="opt_data"
              :selectedLevels="['opt']"
              :tableRowClickFunction='handle_row_click'
              :numericColumns="numeric_cols"
              :currencyColumns='currency_cols'
              :percentageColumns="change_cols"
              :short="true"
            />
        </v-col>
      </v-row>
    </v-container>
</template>
  
<script>
  // import { GChart } from "vue-google-charts/legacy";
  import { ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE } from "@/constants/constants";
  import predictionConfig from "@/configs/Planning/prediction_line_chart.json";
  import PredictionPieChart from "./Charts/PredictionPieChart.vue"
  import PredictionBarChartOverview from "./Charts/PredictionBarChartOverview.vue";
  // import PredictionRadarChartOverview from "./Charts/PredictionRadarChartOverview.vue";

  // import PredictionComparison from "./Charts/PredictionComparison.vue";
  import PredictionExample from "@/test_data/planning/planning_example_a.json";

  import DataLevelTable from "@/components/Utility/DataLevelTable.vue"

  // import PredictionHighlight from "@/components/Planning/PlanningSetup/Charts/PredictionHighlight.vue"
  import PredictionStatistic from "./Charts/PredictionStatistic.vue";

  import api_caller from "@/javascript/optimization.js";

  export default {
    name: "PlanningPrediction",
    props: [
      'project_plan_id'
    ],
    components: {
      // GChart,
      PredictionPieChart,
      DataLevelTable,
      PredictionBarChartOverview,
      // PredictionRadarChartOverview,
      PredictionStatistic
      // PredictionHighlight
      // PredictionComparison
    },
    data: () => ({
      data_loading: true,

      optimization_settings_menu: false,
      optimization_settings: {
        'display': ['base','low','mid','high']
      },

      prediction_chart_config: predictionConfig,

      data_raw: null, 
      available_level_vals: ["Media","OOH","Programmatic","Social","Search"],
      selected_level_val: "Media",
      data: PredictionExample['overview']['Media']['daily'],
      attribution: PredictionExample['overview']['Media']['Attribution'],
      change_last: PredictionExample['overview']['Media']['Change_Last'],
      change_prev: PredictionExample['overview']['Media']['Change_Prev'],

      roas_compare: PredictionExample['roas'],
      contribution_compare: PredictionExample['contribution'],

      optimizations: [],
      optimization_data: {},
      my_optimization: 'overview',

      headers: [],
      opt_data: [],
      opt_overview_data: {},
      base_pie_data: null,
      low_pie_data: null,
      mid_pie_data: null,
      high_pie_data: null,

      prediction_overview_data: null

    }),
    mounted(){
      this.data_raw = PredictionExample;
      this.buildPrediction();
    },
    watch: {
      project_plan_id: function(){
        this.buildPrediction();
      }
    },
    computed: {
        numeric_cols() {
          return [];
        },
        currency_cols() {
          let cols = ['spend','volume','roas'];
          // for(let display_col of this.optimization_settings['display']){
          //   cols.push(display_col);
          //   cols.push(display_col+"_vol");
          //   cols.push(display_col+"_roas");
          // }
          // console.log(cols);
          return cols
        },
        change_cols(){
          let change_cols = ['spend_change','volume_change','roas_change'];
          // for(let display_col of this.optimization_settings['display']){
          //   change_cols.push((display_col + "_change"));
          //   change_cols.push((display_col + "_change_vol"));
          //   change_cols.push((display_col + "_change_roas"));
          // }
          return change_cols
        },
        optimization_header: function(){
          let new_header = []
          for(let header of this.headers){
            console.log(header)
            new_header.push(header)
            // if((header['value'] === 'opt') || (this.optimization_settings['display'].includes(header['value'])) || (this.optimization_settings['display'].includes(header['value'].replace("_roas","").replace("_vol","").replace("_change","")))){
            //   new_header.push(header)
            // }
          }
          console.log(new_header)
          return new_header;
        }
    },
    methods: {
      generate_gchart_format: function(data){
          let rows = [];
          let row_tmp = [];
          for(let row of data){
            row_tmp = []
            for(let val of row){
                row_tmp.push({"f": null, "v": val});
            }
            rows.push({"c": row_tmp})
          }
          return {"cols": this.prediction_header, "rows": rows}
      },
      updateLevelVal: function(indexShift){
        let curIdx = this.available_level_vals.indexOf(this.selected_level_val) + indexShift;
        this.selected_level_val = this.available_level_vals[curIdx];
      },
      buildPrediction: async function(){
        this.data_loading = true;
        let token = await this.$auth.getTokenSilently();
        let payload = {
          'project_id': this.$project.get_val(),
          'project_plan_id': this.project_plan_id.toString()
        }
        let prediction_data = await api_caller.get_optimization_data(token, payload).then((resp)=>{
                              if(resp['success']) {
                                return resp['data'];
                              }
                              return false
                          }).catch((e)=>{
                              console.log('error', e);
                              return false;
                          });
        console.log('prediction', prediction_data);
        if(prediction_data){
          // this.opt_data = prediction_data['optimization_table'];
          // this.opt_overview_data = prediction_data['optimization_overview']
          // this.optimization_settings = {
          //   'display': prediction_data['display']
          // }
          // console.log('opt',this.optimization_settings)
          // this.headers = prediction_data['optimization_header']

          this.opt_data = prediction_data['summary_tbl'];
          this.opt_overview_data = []
          this.headers = prediction_data['summary_tbl_header'];
          this.prediction_overview_data = prediction_data['overview'];

          this.optimizations = prediction_data['optimizations'];
          this.optimization_data = prediction_data['optimization_data'];

          this.data_loading = false;
        }
        else{
          this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
        }
                        
        // if(prediction_data){
        //   this.opt_data = prediction_data['optimization_data'];
        // }
        // this.data_raw = prediction_data;
        // this.available_level_vals = prediction_data['data_levels'];
        // if(this.available_level_vals && this.available_level_vals.length > 0){
        //   this.selected_level_val = this.available_level_vals[0];
        // }
        // else{
        //   this.selected_level_val = null;
        // }
        // this.data_loading = false;
        // console.log(prediction_data);
      },
      empty_table_click: function(){
        
      },
      handle_row_click: function(value){
        console.log(value)
        if(value['opt'] !== 'Base'){
          this.my_optimization = value['opt'];
        }
      }
    }
  };
  </script>
  
  <style>
  .graph_select_title{
    line-height: 1.5em;
    font-family: "Poppins";
    font-weight: 600 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(80%);
    text-align: left;
    overflow: hidden;
  }
  .graph_select_options{
    line-height: 1em;
    font-family: "Poppins";
    font-weight: 400 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(100%);
    text-align: left;
    overflow: hidden;
  }
  .optimization_calculator_action {
    font-family: 'Poppins' !important;
    font-weight: 400 ;
  }
  .prediction_title{
    font-family: 'Poppins' !important;
    font-weight: 700 ;
  }
  </style>
  