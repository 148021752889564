<template>
    <v-container class="px-6 pt-0 pb-3" style="max-width: 100%;">
      <v-overlay :value="response_curve_optimization" ref="uniqueReachCurveCard">
        <v-card light style="width: 90vw; height: 90vh; background-color: white;">
          <v-row class="ma-0 pa-0">
            <v-col cols="auto">
              <span class="optimization_response_curve_title"> {{selected_response_curve_channel}} Response Curve </span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon @click="response_curve_optimization = false;" color="black" v-if="!selected_response_curve_shift">mdi-close</v-icon>
              <v-icon @click="save_response_curve_selection();" color="black" v-else>mdi-content-save</v-icon>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col cols="12">
              <GChart
                :settings="{ packages: ['corechart', 'table'] }"
                style="width: 100%; height: calc(90vh - (170px));"
                type="LineChart"
                :data="selected_response_curve"
                :options="chartOptions"
                :events="chartEvents"
                ref="uniqueReachCurve"/>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0" justify="space-around">
            <v-col>
              <PerformanceMainStatistic statistic_title_name="Spend" :value="selected_response_curve_spend"></PerformanceMainStatistic>
            </v-col>
            <v-col>
              <PerformanceMainStatistic statistic_title_name="Est. Vol." :value="selected_response_curve_volume"></PerformanceMainStatistic>
            </v-col>
            <v-col>
              <PerformanceMainStatistic statistic_title_name="Est. ROAS" :value="selected_response_curve_roas"></PerformanceMainStatistic>
            </v-col>
          </v-row>
        </v-card>
      </v-overlay>
      <v-row class="ma-0 pa-0" style="width: 100%; height: 100%" align="center" justify="space-around">
        <v-col cols="12" style="width: 100%; height: 100%;">
          <v-row class="ma-0 pa-0"> 
            <v-col cols="10" class="ma-0 pa-0" v-if="!loading">
              <v-chip label class="data_breakdown_chip" v-for="(opt) in optimizations" :key="opt.ID" color="black" :outlined="my_optimization !== opt.ID" @click="my_optimization = opt.ID">
                {{opt.Name}}
              </v-chip>
              <v-menu
                v-model="add_optimization_menu"
                :close-on-content-click="false"
                :nudge-width="200"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: ontooltip }">
                      <v-btn icon elevation="0" v-on="ontooltip">
                        <v-icon color="black" :disabled="optimizations.length > 4 || lock_changes" v-bind="attrs" v-on="on" >mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <TooltipDescription title="Add Optimization" desc_2=""></TooltipDescription>
                  </v-tooltip>
                  <!-- <v-icon class="mx-3" color="black" v-bind="attrs" v-on="on" :disabled="optimizations.length > 4">mdi-plus</v-icon> -->
                </template>
                <v-card>
                  <v-card-title class="optimization_create_title">Create Optimization</v-card-title>
                  <v-card-text class="ma-0 pa-3">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-text-field v-model="new_optimization_title" class="ma-0 pa-0 optimization_calculator_action" label="Optimization Name"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <span class="optimization_calculator_action">Risk Level</span>
                      </v-col>
                      <v-col cols="12" class="ma-0">
                        <v-radio-group v-model="new_optimization_risk" class="ma-0 pa-0 optimization_calculator_action">
                          <v-radio value="low" label="Low Risk"></v-radio>
                          <v-radio value="mid" label="Mid Risk"></v-radio>
                          <v-radio value="high" label="High Risk"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined class="optimization_calculator_action"  @click="new_optimization_title=''; new_optimization_risk='low'; add_optimization_menu = false"> Cancel </v-btn>
                    <v-btn outlined class="optimization_calculator_action" @click="add_optimization_menu = false; add_new_optimization();" :disabled="new_optimization_title === ''"> Create </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
              <v-tooltip top>
                <template v-slot:activator="{ on: ontooltip }">
                  <v-btn icon elevation="0" v-on="ontooltip" @click="copy_optimization" :disabled="optimizations.length > 4 || lock_changes">
                    <v-icon color="black">mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <TooltipDescription title="Copy Current Optimization" desc_2=""></TooltipDescription>
              </v-tooltip>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" v-if="!loading">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon color="black" v-on="on" :disabled="!save_optimizations" @click="save_optimization">mdi-content-save-outline</v-icon>
                </template>
                <TooltipDescription title="Save Optimizations" desc_2="Overwrite existing Optimizations. Unless you have built optimizations any settings will be permanently overwritten."></TooltipDescription>
              </v-tooltip>
            </v-col>
            <v-col cols="12"  v-if="loading">
              <v-row class="ma-0 pa-0" justify="center" :align="'center'" style="height: 100%">
                <v-col cols="auto" class="ma-0 pa-0">
                  <v-progress-circular
                    size="100"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0" v-if="!loading">
              <v-row class="ma-0 pa-0">
                <v-col cols="9" style="height: 500px; overflow-y: scroll;">
                  <v-row class="ma-0 pa-0" align="center">
                    <v-col cols="6" class="py-1">
                      <v-text-field class="optimization_calculator_action" v-model="my_optimization_name" :disabled="optimization_channels.length==0 || lock_changes" @change="update_optimization_name"></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="ma-0 pa-3">
                      <v-row class="ma-0 pa-0" align="center">
                        <v-col cols="8" class="ma-0 pa-0">
                          <v-text-field class="optimization_calculator_action" v-model="all_min_change" type="number" :disabled="optimization_channels.length==0 || lock_changes"></v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="ma-0 pa-0">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn icon elevation="0" v-on="on" @click="set_all_min_change()" :disabled="optimization_channels.length==0 || lock_changes">
                                <v-icon color="black">mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <TooltipDescription title="Update All Min Change" desc_2=""></TooltipDescription>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <!-- <v-text-field v-if="!channel['lock']" v-model="channel['min_change']" class="mx-3 optimization_calculator_action" label="Negative Max Change (-%)" :disabled="channel['lock'] || lock_changes" type="number" suffix="%" @change="save_optimizations=true;"></v-text-field>
                      <v-text-field v-else class="mx-3 optimization_calculator_action" value="0" label="Negative Max Change (-%)" disabled type="number" suffix="%"></v-text-field> -->
                    </v-col>
                    <v-col cols="2" class="ma-0 pa-3">
                      <v-row class="ma-0 pa-0" align="center">
                        <v-col cols="8" class="ma-0 pa-0">
                          <v-text-field class="optimization_calculator_action" v-model="all_max_change" type="number" :disabled="optimization_channels.length==0 || lock_changes"></v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="ma-0 pa-0">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn icon elevation="0" v-on="on" @click="set_all_max_change()" :disabled="optimization_channels.length==0 || lock_changes">
                                <v-icon color="black">mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <TooltipDescription title="Update All Max Change" desc_2=""></TooltipDescription>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <!-- <v-text-field class="ma-0 pa-0" style="max-width: 60%;"></v-text-field>
                      <v-icon>mdi-plus</v-icon> -->
                      <!-- <v-text-field v-if="!channel['lock']" v-model="channel['max_change']" class="mx-3 optimization_calculator_action" label="Positive Max Change (+%)" :disabled="channel['lock'] || lock_changes" type="number" suffix="%" @change="save_optimizations=true;"></v-text-field>
                      <v-text-field v-else class="mx-3 optimization_calculator_action" value="0" label="Positive Max Change (+%)" disabled type="number" suffix="%"></v-text-field> -->
                    </v-col>
                    <v-col cols="auto" class="ma-0 pa-0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon elevation="0" v-on="on">
                            <v-icon color="black" :disabled="optimizations.length<2 || lock_changes" @click="delete_optimization">mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <TooltipDescription title="Delete Optimization" desc_2=""></TooltipDescription>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0 pa-0"  v-for="channel, idx in optimization_channels" :key="idx" align="center">
                    <v-col cols="auto" class="ma-0 px-0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon elevation="0" v-on="on">
                            <v-icon color="black" :disabled="channel['lock'] || lock_changes"  @click="open_response_curve(channel['channel'])">mdi-chart-bell-curve-cumulative</v-icon>
                          </v-btn>
                        </template>
                        <TooltipDescription title="Response Curve" desc_2=""></TooltipDescription>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="2" class="ma-0 pa-3" align="left"><span class="optimization_calculator_channel">{{channel['channel']}}</span></v-col>
                    <v-col cols="2" class="ma-0 pa-3" align="left">
                      <span class="optimization_calculator_spend">
                        {{
                        channel['spend'].toLocaleString("en-CA", {
                          style: "currency",
                          currency: "CAD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="ma-0 pa-0">
                      <v-text-field v-if="!channel['lock']" v-model="channel['min_change']" class="mx-3 optimization_calculator_action" label="Negative Max Change (-%)" :disabled="channel['lock'] || lock_changes" type="number" suffix="%" @change="save_optimizations=true;"></v-text-field>
                      <v-text-field v-else class="mx-3 optimization_calculator_action" value="0" label="Negative Max Change (-%)" disabled type="number" suffix="%"></v-text-field>
                    </v-col>
                    <v-col cols="2" class="ma-0 pa-0">
                      <v-text-field v-if="!channel['lock']" v-model="channel['max_change']" class="mx-3 optimization_calculator_action" label="Positive Max Change (+%)" :disabled="channel['lock'] || lock_changes" type="number" suffix="%" @change="save_optimizations=true;"></v-text-field>
                      <v-text-field v-else class="mx-3 optimization_calculator_action" value="0" label="Positive Max Change (+%)" disabled type="number" suffix="%"></v-text-field>
                    </v-col>
                    <!-- <v-col cols="3" class="ma-0 pa-0">
                      <v-text-field v-if="!channel['lock']" :value="(((channel['max_change'] / 100) * channel['spend']) + channel['spend']).toLocaleString('en-CA', {minimumFractionDigits: 0,maximumFractionDigits: 2})" class="mx-3 optimization_calculator_action" label="Max Change ($)" :disabled="channel['lock'] || lock_changes" prefix="$" @change="channel_spend_change($event,channel); save_optimizations=true;"></v-text-field>
                      <v-text-field v-else class="mx-3 optimization_calculator_action" value="0" label="Max Change (%)" disabled type="number" suffix="%"></v-text-field>
                    </v-col> -->
                    <v-col cols="auto" class="ma-0 pa-0">
                      <v-tooltip top v-if="channel['lock']">
                        <template v-slot:activator="{ on }">
                          <v-btn icon elevation="0" v-on="on">
                            <v-icon color="black" @click="channel['lock'] = !channel['lock']; save_optimizations=true;" :disabled="lock_changes">mdi-lock</v-icon>
                          </v-btn>
                        </template>
                        <TooltipDescription title="Investment Locked" desc_2=""></TooltipDescription>
                      </v-tooltip>
                      <v-tooltip top v-if="!channel['lock']">
                        <template v-slot:activator="{ on }">
                          <v-btn icon elevation="0" v-on="on">
                            <v-icon color="black" @click="channel['lock'] = !channel['lock']; save_optimizations=true;" :disabled="lock_changes">mdi-lock-open-outline</v-icon>
                          </v-btn>
                        </template>
                        <TooltipDescription title="Investment Unlocked" desc_2=""></TooltipDescription>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="3">
                  <span class="optimization_setup_title">Total Budget</span>
                  <v-row class="ma-0 pa-0" align="center" justify="space-between">
                    <v-col cols="7" class="ma-0 pa-0">
                      <v-text-field class="optimization_calculator_action" v-model="optimization_total_budget" :disabled="optimization_channels.length==0 || lock_changes" type="number" prefix="$" @change="update_opt_setup_vars"></v-text-field>
                    </v-col>
                    <v-col cols="auto" class="ma-0 pa-0">
                      <v-icon color="black">mdi-plus-minus-variant</v-icon>
                    </v-col>
                    <v-col cols="3" class="ma-0 pa-0">
                      <v-text-field class="optimization_calculator_action" v-model="optimization_total_budget_pm" :disabled="optimization_channels.length==0 || lock_changes" type="number" suffix="%" @change="update_opt_setup_vars"></v-text-field>
                    </v-col>
                  </v-row>
                  <span class="optimization_setup_title">Optimization Strategy</span>
                  <v-radio-group class="optimization_calculator_action" v-model="optimization_strategy" :disabled="optimization_channels.length==0 || lock_changes" @change="update_opt_setup_vars">
                    <v-radio label="ROAS" value="roas"></v-radio>
                    <v-radio label="Attribution" value="attribution"></v-radio>
                    <v-radio label="Ladder" value="ladder" disabled></v-radio>
                  </v-radio-group>
                  <span class="optimization_setup_title">Optimization Calculator</span>
                  <v-radio-group class="optimization_calculator_action" v-model="optimization_calculator" :disabled="optimization_channels.length==0 || lock_changes" @change="update_opt_setup_vars">
                    <v-radio label="Bulk" value="bulk"></v-radio>
                    <v-radio label="Flighted" value="flighting" disabled></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  // import DateRange from "@/components/Utility/DateRange.vue";
  // import performance_data_header from "@/input_data/performance_table_header.json";
  // import PredictionPieChart from "@/components/Planning/PlanningSetup/Charts/PredictionPieChart.vue"
  import TooltipDescription from "@/components/Utility/TooltipDescription.vue";
  // import ResponseCurve from "@/components/Planning/PlanningSetup/Charts/ResponseCurve.vue"
  // import PredictionStatistic from "./Charts/PredictionStatistic.vue"

  import PerformanceMainStatistic from "@/components/Performance/PerformanceStats/PerformanceReportSections/PerformanceMainStatistic.vue";

  // import PlanningOptimizationSetup from "./PlanningOptimizationSetup.vue"
  import { GChart } from "vue-google-charts/legacy";

  import api_caller from "@/javascript/planning.js"

  export default {
    name: "PlanningOptimization",
    props: [
      'data_version',
      'data_levels',
      'project_date_range',
      'lock_changes',
      'project_plan_id'
    ],
    components: {
      TooltipDescription,
      PerformanceMainStatistic,
      GChart
    },
    data: () => ({
      loading: true,
      add_optimization_menu: false,

      optimizations: [],
      my_optimization: null,
      my_optimization_name: '',

      optimization_setups: {},
      optimization_total_budget: 0,
      optimization_total_budget_pm: 0,
      optimization_strategy: 'bulk',
      optimization_calculator: 'roas',
      optimization_channels: [],

      new_optimization_title: '',
      new_optimization_risk: 'low',

      save_optimizations: false,

      response_curve_optimization: false,
      response_curves: {},
      selected_response_curve: [],
      selected_response_curve_channel: '',
      selected_response_curve_spend: 0,
      selected_response_curve_volume: 0,
      selected_response_curve_roas: 0,
      selected_response_curve_shift: false,

      chartOptions: {
        titleTextStyle: {fontName: 'Poppins', fontSize: 18, bold: false},
        colors: ['#0079c1','#AD343E','#F2AF29','#FF3CC7','#00E5E8','#628B48', '#7D3AC1', '#DE542C', '#820401', '#7FD62F'],
        vAxes: { 0:{title: 'Volume ($)', titleTextStyle:{fontName: 'Poppins', fontSize: 16, italic: false}, textStyle:{fontName: 'Poppins', fontSize: 12}}},
        vAxis: { viewWindowMode: 'explicit', baselineColor: '#000000', viewWindow: {min: 0}, gridlines:{count: 0}, minorGridlines:{count: 0}},
        hAxis: {viewWindowMode: 'explicit', viewWindow:{min: 0}, title: 'Budget in $', minValue: 0, format:'currency', slantedText: true, slantedTextAngle: 15, baselineColor: '#000000', textStyle:{fontName: 'Poppins', fontSize: 12}, titleTextStyle:{fontName: 'Poppins', fontSize: 16, italic: false}, gridlines:{count: 0}, minorGridlines:{count: 0}},
        curveType: 'function',
        legend: { position: 'none', maxLines: 4},
        chartArea: {left: '10%', top: '2%', width: '87%', height: '90%'},
        pointSize: 5,
        backgroundColor: 'none',
        fontName: 'Poppins'
      },

      chartEvents: {},

      all_min_change: 0,
      all_max_change: 10

    }),
    mounted(){
      this.reset_table();
      this.chartEvents = {
        select: (event) => {
          this.handleChartSelect(event);
        }
      };
    },
    watch: {
      project_plan_id: function(){
        this.reset_table();
      },
      my_optimization: function(){
        console.log('my_opt_shift', this.my_optimization)
        if(this.my_optimization in this.optimization_setups){
          let opt_setup = this.optimization_setups[this.my_optimization];

          this.optimization_total_budget = opt_setup['total_budget'];
          this.optimization_total_budget_pm = opt_setup['total_budget_shift'];
          this.optimization_strategy = opt_setup['strategy'];
          this.optimization_calculator = opt_setup['calculator'];
          this.optimization_channels = opt_setup['optimization'];
          this.my_optimization_name = opt_setup['title'];
        }
      }
    },
    computed: {
    },
    methods: {
      update_optimization_name: function(){
        this.optimization_setups[this.my_optimization]['title'] = this.my_optimization_name;
        for(let idx = 0 ; idx < this.optimizations.length; idx ++){
          if(this.optimizations[idx]['ID'] == this.my_optimization){
            this.optimizations[idx]['Name'] = this.my_optimization_name;
          }
        }
        this.save_optimizations = true;
      },
      channel_spend_change: function(item, channel){
        let new_spend = item.replaceAll(',', ''); 
        new_spend = parseFloat(new_spend) - channel['spend'];
        channel['max_change'] = (new_spend / channel['spend']) * 100;
      },
      reset_table: async function(){
        this.loading = true;
        let token = await this.$auth.getTokenSilently();
        let payload = {
          'project_id': this.$project.get_val(),
          'project_plan_id': this.project_plan_id.toString()
        }
        let data = await api_caller.get_optimization_info(token,payload).then((resp)=>{
                                  if(!resp['success']){
                                    return false
                                  }
                                  return resp['data']
                                }).catch((e)=>{
                                    console.log(e)
                                    return 'False'
                                });
        this.response_curves = data['response_curves']
        this.assign_optimization(data);
      },
      add_new_optimization: async function(){
        this.loading = true;
        if(this.save_optimizations){
          await this.save_optimization(false)
        }
        let token = await this.$auth.getTokenSilently();
        let payload = {
          'project_id': this.$project.get_val(),
          'project_plan_id': this.project_plan_id.toString(),
          'optimization_title': this.new_optimization_title,
          'optimization_risk': this.new_optimization_risk
        }
        let data = await api_caller.add_new_optimization(token,payload).then((resp)=>{
                                  if(!resp['success']){
                                    return false
                                  }
                                  return resp['data']
                                }).catch((e)=>{
                                    console.log(e)
                                    return 'False'
                                });
        this.assign_optimization(data);
        this.new_optimization_title = '';
        this.new_optimization_risk = 'low';
      },
      copy_optimization: async function() {
        this.loading = true;
        if(this.save_optimizations){
          await this.save_optimization(false)
        }
        let token = await this.$auth.getTokenSilently();
        let payload = {
          'project_id': this.$project.get_val(),
          'project_plan_id': this.project_plan_id.toString(),
          'optimization_id': this.my_optimization
        }
        let data = await api_caller.copy_optimization(token,payload).then((resp)=>{
                                  if(!resp['success']){
                                    return false
                                  }
                                  return resp['data']
                                }).catch((e)=>{
                                    console.log(e)
                                    return 'False'
                                });
        this.assign_optimization(data);
      },
      delete_optimization: async function(){
        this.loading = true;
        if(this.save_optimizations){
          await this.save_optimization(false)
        }
        let token = await this.$auth.getTokenSilently();
        let payload = {
          'project_id': this.$project.get_val(),
          'project_plan_id': this.project_plan_id.toString(),
          'optimization_id': this.my_optimization
        }
        let data = await api_caller.delete_optimization(token,payload).then((resp)=>{
                                  if(!resp['success']){
                                    return false
                                  }
                                  return resp['data']
                                }).catch((e)=>{
                                    console.log(e)
                                    return false;
                                });
        this.assign_optimization(data);
      },
      update_opt_setup_vars: function(){
        this.optimization_setups[this.my_optimization]['total_budget'] = this.optimization_total_budget;
        this.optimization_setups[this.my_optimization]['total_budget_shift'] = this.optimization_total_budget_pm;
        this.optimization_setups[this.my_optimization]['strategy'] = this.optimization_strategy;
        this.optimization_setups[this.my_optimization]['calculator'] = this.optimization_calculator;
        this.save_optimizations=true;
      },
      save_optimization: async function(reload_data=true){
        let optimizations = [];
        for(let id of Object.keys(this.optimization_setups)){
          optimizations.push(this.optimization_setups[id]);
        }
        this.loading = true;
        let token = await this.$auth.getTokenSilently();
        let payload = {
          'project_id': this.$project.get_val(),
          'project_plan_id': this.project_plan_id.toString(),
          'optimizations': optimizations
        }
        let data = await api_caller.update_optimization(token, payload).then((resp)=>{
                                  if(!resp['success']){
                                    return false
                                  }
                                  return resp['data']
                                }).catch((e)=>{
                                    console.log(e)
                                    return false;
                                });
        this.save_optimizations=false;
        if(reload_data){
          this.assign_optimization(data);
          this.loading=false;
        }
      },
      assign_optimization: function(data){
        console.log(data)
        if(data){
          this.performanceData = data;
          let opt_setups = this.performanceData['setups'];
          this.optimization_setups = {}
          for(let setup of opt_setups){
            this.optimization_setups[setup['id']] = setup
          }

          this.optimizations = this.performanceData['optimizations'];
          this.my_optimization = null
          setTimeout( () => this.my_optimization = this.optimizations[0]['ID'], 100)

          this.loading = false;
        }
        else{
          this.performanceData = null;
          this.loading = true;
        }
      },
      set_all_min_change(){
        let min_change = this.all_min_change;
        for(let opt of this.optimization_setups[this.my_optimization]['optimization']){
          opt['min_change'] = min_change;
        }
        this.save_optimizations=true;
      },
      set_all_max_change(){
        let max_change = this.all_max_change;
        for(let opt of this.optimization_setups[this.my_optimization]['optimization']){
          opt['max_change'] = max_change;
        }
        this.save_optimizations=true;
      },
      open_response_curve: function(channel){
        let channel_spend = 0;
        for(let opt of this.optimization_setups[this.my_optimization]['optimization']){
          if(opt['channel'] == channel){
            channel_spend = opt['spend'];
          }
        }
        let new_resp_curve = []
        let cur_idx = 0;
        let curve_spend = 0;
        let curve_vol = 0;
        let continue_running = false;
        for(let data_row of this.response_curves[channel]){
          if(cur_idx == 0){
            new_resp_curve.push([data_row[0],data_row[1], "point"]);
          }
          else{
            new_resp_curve.push([data_row[0],data_row[1],null]);
            
            if(data_row[0] > channel_spend && !continue_running){
              if(Math.abs(data_row[0] - channel_spend) > Math.abs(this.response_curves[channel][cur_idx][0] - channel_spend)){
                curve_spend = this.response_curves[channel][cur_idx][0];
                curve_vol = this.response_curves[channel][cur_idx][1];
              }
              else{
                curve_spend = this.response_curves[channel][cur_idx - 1][0];
                curve_vol = this.response_curves[channel][cur_idx - 1][1];
              }
              continue_running = true;
            }
          }
          cur_idx += 1;
        }
        new_resp_curve.push([curve_spend, null, 0]);
        new_resp_curve.push([curve_spend, null, curve_vol]);
        // this.selected_response_curve = this.response_curves[channel];
        this.selected_response_curve = new_resp_curve;
        this.response_curve_optimization = true;
        this.selected_response_curve_channel = channel;
        this.selected_response_curve_spend = curve_spend;
        this.selected_response_curve_volume = curve_vol;
        this.selected_response_curve_roas = curve_vol / curve_spend;
      },
      handleChartSelect() {
        this.$nextTick(() => {
          // const uniqueReachCardRef = this.$refs.uniqueReachCurve;
          const table = this.$refs.uniqueReachCurve.chartObject;
          const data = this.$refs.uniqueReachCurve.data;
          const selection = table.getSelection()[0];
          if (selection != null) {
            const row = selection.row;
            const channel_spend = data[row][0];
            
            let channel = this.selected_response_curve_channel;
            let new_resp_curve = []
            let cur_idx = 0;
            let curve_spend = 0;
            let curve_vol = 0;
            let continue_running = false;
            for(let data_row of this.response_curves[channel]){
              if(cur_idx == 0){
                new_resp_curve.push([data_row[0],data_row[1], "point"]);
              }
              else{
                new_resp_curve.push([data_row[0],data_row[1],null]);
                
                if(data_row[0] > channel_spend && !continue_running){
                  if(Math.abs(data_row[0] - channel_spend) > Math.abs(this.response_curves[channel][cur_idx][0] - channel_spend)){
                    curve_spend = this.response_curves[channel][cur_idx][0];
                    curve_vol = this.response_curves[channel][cur_idx][1];
                  }
                  else{
                    curve_spend = this.response_curves[channel][cur_idx - 1][0];
                    curve_vol = this.response_curves[channel][cur_idx - 1][1];
                  }
                  continue_running = true;
                }
              }
              cur_idx += 1;
            }
            new_resp_curve.push([curve_spend, null, 0]);
            new_resp_curve.push([curve_spend, null, curve_vol]);



            this.selected_response_curve = new_resp_curve;
            this.selected_response_curve_spend = data[row][0];
            this.selected_response_curve_volume = data[row][1];
            this.selected_response_curve_roas = data[row][1] / data[row][0];
            this.selected_response_curve_shift = true;
          }
        });
      },
      save_response_curve_selection: async function(){
        this.loading = true;
        this.selected_response_curve_shift = false;
        this.response_curve_optimization = false;

        if(this.save_optimizations){
          await this.save_optimization(false)
        }
        let token = await this.$auth.getTokenSilently();
        let payload = {
          'project_id': this.$project.get_val(),
          'project_plan_id': this.project_plan_id.toString(),
          'optimization_id': this.my_optimization,
          'channel': this.selected_response_curve_channel,
          'spend': this.selected_response_curve_spend
        }
        let data = await api_caller.edit_response_curve(token,payload).then((resp)=>{
                                  if(!resp['success']){
                                    return false
                                  }
                                  return resp['data']
                                }).catch((e)=>{
                                    console.log(e)
                                    return false;
                                });
        this.assign_optimization(data);
      }
    }
  };
  </script>
  
  <style>
  .optimization_response_curve_title {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
    font-size: 1.1em;
  }
  .data_breakdown_chip {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
    font-size: 0.85em;
    width: 150px;
  }
  .optimization_setup_title {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
    font-size: 0.85em;
  }
  .optimization_create_title {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
  }
  .optimization_calculator_channel {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
  }
  .optimization_calculator_spend {
    font-family: 'Poppins' !important;
    font-weight: 400 ;
  }
  .optimization_calculator_action {
    font-family: 'Poppins' !important;
    font-weight: 400 ;
  }
  </style>
  