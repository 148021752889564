var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-0 pa-0",staticStyle:{"height":"100%","max-height":"100%","overflow-y":"auto"},attrs:{"flat":""}},[_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"report-subtitle"},[_vm._v("Time Series Contribution Comparison")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","disabled":""}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-cog")])],1)],1),_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"height":"10px"},attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mx-12"})],1),(!_vm.graph_visible)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-skeleton-loader',{style:({
                      height: _vm.screenHeight + 'px',
                    }),attrs:{"light":"","type":"image,image,image,image"}})],1),_c('v-col',{staticClass:"ma-0 pl-0",attrs:{"cols":"2"}},[_c('v-skeleton-loader',{style:({
                      height: _vm.screenHeight + 'px',
                    }),attrs:{"light":"","type":"image,image,image,image"}})],1),_vm._l(([1,2,3,4]),function(idx){return _c('v-col',{key:idx,staticClass:"ma-0",attrs:{"cols":"2"}},[_c('v-skeleton-loader',{style:({
                      height: _vm.screenHeight + 'px',
                    }),attrs:{"light":"","type":"image,image,image,image"}})],1)}),_c('v-col',{staticClass:"ma-0 pr-0",attrs:{"cols":"2"}},[_c('v-skeleton-loader',{style:({
                      height: _vm.screenHeight + 'px',
                    }),attrs:{"light":"","type":"image,image,image,image"}})],1)],2)],1):_vm._e(),(_vm.graph_visible)?_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"height":"calc(100% - 10px)"},attrs:{"cols":_vm.main_graph_section_cols}},[_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"chartAlign ma-0 pb-0",attrs:{"cols":"12"}},[_c('div',{style:({
              height: _vm.screenHeight + 'px'
            })},[_c('GChart',{staticClass:"performanceChart",attrs:{"type":"AreaChart","data":_vm.graph_data,"options":_vm.graph_config,"resizeDebounce":50}})],1)]),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('div',{class:{'scrollable-container': _vm.datasetb.length > 6}},[_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"flex-wrap":"nowrap"},attrs:{"justify":"space-around"}},_vm._l((_vm.datasetb),function(qoq,idx){return _c('v-col',{key:idx,style:({height: _vm.screenHeight + 'px'}),attrs:{"cols":"2"}},[_c('CompPart',{attrs:{"dataset":qoq}})],1)}),1)],1)])],1)],1):_vm._e()],1),_c('v-spacer'),(_vm.comments_section)?_c('v-col',{attrs:{"cols":"4"}},[_c('CommentSection',{attrs:{"comments_persistent":_vm.comments_persistent},on:{"close_comments":_vm.close_comments}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }