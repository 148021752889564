<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="12" class="ma-0 pa-0">
      <v-row class="ma-0 pa-0" justify="space-around">
        <v-col cols="12" class="mt-6" v-if="tbl_avaialable">
          <v-row class="ma-0 pa-0">
            <v-col cols="9" class="ma-0 pa-0">
              <GChart
                style="height: calc(100vh - 380px)"
                type="LineChart"
                :options="portfolio_sos_config"
                :data="portfolio_sos_data"
              ></GChart>
            </v-col>
            <v-col cols="3" class="ma-0 pa-0">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-1 pt-0" style="height: calc((100vh - 404px) / 3);">
                  <SalesOverviewStatistic statistic_title_name="Sales" :data="sales_summary['sales']"></SalesOverviewStatistic>
                </v-col>
                <v-col cols="12" class="ma-0 pa-1 py-2" style="height: calc((100vh - 404px) / 3);">
                  <SalesOverviewStatistic statistic_title_name="Share of Search" :data="sales_summary['sos']"></SalesOverviewStatistic>
                </v-col>
                <v-col cols="12" class="ma-0 pa-1 pb-0" style="height: calc((100vh - 404px) / 3);">
                  <SalesOverviewStatistic statistic_title_name="Competitive Share of Search" :data="sales_summary['csos']"></SalesOverviewStatistic>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mt-6" v-else>
          <v-row class="ma-0 pa-0">
            <v-col cols="9" class="ma-0 pa-0" style="height: calc(100vh - 380px);">
              <v-skeleton-loader
                light
                style="height: calc(100vh - 380px); max-height: none"
                type="image,image,image"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="ma-0 pa-0">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-1 pt-0">
                  <v-skeleton-loader
                    light
                    style="height: calc((100vh - 404px) / 3); max-height: none"
                    type="image,image"
                  ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" class="ma-0 pa-1 py-2">
                  <v-skeleton-loader
                    light
                    style="height: calc((100vh - 404px) / 3); max-height: none"
                    type="image,image"
                  ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" class="ma-0 pa-1 pb-0">
                  <v-skeleton-loader
                    light
                    style="height: calc((100vh - 404px) / 3); max-height: none"
                    type="image,image"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col cols="12" class="chart_container">
          <v-sheet minHeight="20vh">
            <v-lazy v-model="isSpendContImpActive" :options="{ threshold: 0.7 }">
              <SpendImpressionsContribution
                v-if="isSpendContImpActive"
                :dataset="report_dataset['spend_impressions_contribution']"
                :color_palette="cpalette"
              ></SpendImpressionsContribution>
            </v-lazy>
          </v-sheet>
        </v-col> -->
        <v-col cols="12" class="ma-0 pb-0">
          <v-sheet minHeight="20vh">
            <v-lazy v-model="isTimeSeriesActive" :options="{ threshold: 0.7 }">
              <TimeSeries
                v-if="isTimeSeriesActive"
                :dataset="report_dataset['time_series_comparison']"
                :datasetb="report_dataset['qoq_yoy']['qoq']"
                :color_palette="cpalette"
              ></TimeSeries>
            </v-lazy>
          </v-sheet>
        </v-col>
        <v-col cols="12">
          <v-sheet minHeight="20vh">
            <v-lazy v-model="isVOEActive" :options="{ threshold: 0.7 }">
              <VolumeVsEfficiency
                v-if="isVOEActive"
                :dataset="report_dataset['volume_vs_efficiency']"
                :color_palette="cpalette"
              ></VolumeVsEfficiency>
            </v-lazy>
          </v-sheet>
        </v-col>
        <v-col cols="12">
          <v-sheet minHeight="20vh">
            <v-lazy v-model="isROASActive" :options="{ threshold: 1 }">
              <ROASContribution
                v-if="isROASActive"
                :dataset="report_dataset['roas_contribution']"
                :color_palette="cpalette"
              ></ROASContribution>
            </v-lazy>
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE } from "@/constants/constants";
// import performance_data_header from "@/input_data/performance_table_header.json";
// import performance_data from "@/input_data/Performance/MMM_totals.json";

import ROASContribution from "./PerformanceReportSections/roas_contribution.vue";
import TimeSeries from "./PerformanceReportSections/time_series_comparison.vue";
// import SpendImpressionsContribution from "./PerformanceReportSections/spend_impressions_contribution.vue";
import VolumeVsEfficiency from "./PerformanceReportSections/volume_vs_efficiency.vue";
// import CompPart from "./PerformanceReportSections/comp_part.vue";
import SalesOverviewStatistic from "./PerformanceReportSections/SalesOverviewStatistic.vue";

import chartConfig from "@/configs/Performance/performance_portfolio_sales.json";
import categoryChartConfig from "@/configs/Performance/performance_portfolio_categories.json";
import light_mode_colors from "@/configs/ChartColors/light_mode_colors.json";

import api_caller from "@/javascript/data_retrieval.js";
// import info_caller from "@/javascript/data_info.js";
import { GChart } from "vue-google-charts/legacy";

// import cpalette from "@/configs/ChartColors/get_color_palette.js";

// import cpalette from "@/configs/ChartColors/get_color_palette.js";

export default {
  name: "PerformanceOverview",
  props: ["data_levels", "project_date_range", "project_filters"],
  components: {
    GChart,
    ROASContribution,
    TimeSeries,
    // SpendImpressionsContribution,
    VolumeVsEfficiency,
    // CompPart,
    SalesOverviewStatistic
  },
  data: () => ({
    cpalette: "retro",

    performanceDataRaw: null,
    performanceData: [],
    compareDataRaw: null,
    compare_data: null,
    selected_levels: [],
    selected_fields: [],
    selected_sort_keys: [],
    tbl_avaialable: false,
    toolbar_visible: true,
    portfolio_type: "portfolio",

    views_visible: false,
    selected_view: null,
    save_view: false,
    view_name_tmp: "",
    available_views: [],

    compare: false,
    compare_start_date: null,
    compare_end_date: null,

    isCompPartActive: false,
    isSpendContImpActive: false,
    isTimeSeriesActive: false,
    isVOEActive: false,
    isROASActive: false,

    portfolio_sos_config: chartConfig,
    portfolio_sos_data: [
      ["date", "sos", "csos", "sales"],
      ["2023-01-01", 50, 70, 100000],
      ["2023-01-08", 40, 60, 150000],
      ["2023-01-15", 60, 50, 175000],
      ["2023-01-22", 55, 55, 200000],
      ["2023-01-29", 60, 57, 200000],
    ],

    portfolio_categories_config: categoryChartConfig,
    portfolio_categories_data: [
      {
        stat: "Channel",
        data: [
          ["cat", "val"],
          ["OOH", 10],
          ["Programmatic", 30],
          ["TV", 40],
          ["DOOH", 50],
        ],
      },
      {
        stat: "Format",
        data: [
          ["cat", "val"],
          ["OOH", 10],
          ["Programmatic", 30],
          ["TV", 40],
          ["DOOH", 50],
        ],
      },
    ],

    report_dataset: {
      spend_impressions_contribution: null,
      spend_impressions_contribution_timing: null,
      roas_contribution: null,
      time_series_comparison: null,
      time_series_comparison_roas: null,
      response_curves: null,
      qoq_yoy: {
        qoq: [],
        yoy: [],
      },
      level_values: [],
    },

    start_date: null,
    end_date: null,
  }),
  mounted() {
    this.portfolio_sos_config["colors"] = light_mode_colors;
    this.portfolio_categories_config["colors"] = light_mode_colors;
    this.refresh_data();
  },
  watch: {
    project_date_range: {
      immediate: true,
      handler: function () {
        this.start_date = this.project_date_range["start_date"];
        this.end_date = this.project_date_range["end_date"];
        // this.refresh_data();
      },
      deep: true,
    },
  },
  computed: {
    available_data_levels: function () {
      if (this.performanceDataRaw == null) {
        return [];
      }
      return Object.keys(this.performanceDataRaw["table_levels"]);
    },
  },
  methods: {
    update_volume_vs_efficiency: async function (levels) {
      this.report_dataset["volume_vs_efficiency"] = null;
      let token = await this.$auth.getTokenSilently();
      let date_range = {
        start_date: this.project_date_range["start_date"],
        end_date: this.project_date_range["end_date"],
      };
      let payload = {
        project_id: this.$project.get_val(),
        level_values: levels,
        data_levels: this.data_levels,
        date_range: date_range,
      };
      let volume_efficiency = await api_caller
        .get_updated_volume_efficiency(token, payload)
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          }
          return false;
        })
        .catch((e) => {
          console.log("error", e);
          return false;
        });
      if (!volume_efficiency) {
        this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
        return;
      } else {
        this.report_dataset["volume_vs_efficiency"] =
          volume_efficiency["volume_vs_efficiency"];
      }
    },
    refresh_data: async function () {
      this.tbl_avaialable = false;
      let date_range = {
        start_date: this.start_date,
        end_date: this.end_date,
      };
      let token = await this.$auth.getTokenSilently();

      let portfolio_overview_data = await api_caller
        .get_portfolio_overview_data(
          token,
          this.$project.get_val(),
          this.data_levels,
          date_range,
          this.project_filters
        )
        .then((resp) => {
          if (typeof resp === "string") {
            return JSON.parse(resp)["data"];
          }
          return resp["data"];
        })
        .catch((e) => {
          console.log("error", e);
          return false;
        });

      if (!portfolio_overview_data) {
        this.portfolio_sos_data = [];
      }

      this.report_dataset = portfolio_overview_data;
      this.portfolio_sos_data = portfolio_overview_data["search_sales"];
      this.sales_summary = portfolio_overview_data['sales_summary'];
      this.portfolio_categories_data = portfolio_overview_data["overview"];

      this.reset_table();
    },
    reset_table: function () {
      this.tbl_avaialable = false;
      setTimeout(() => (this.tbl_avaialable = true), 100);
    },
  },
};
</script>

<style>
.table-title {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 1.5em;
}
.performance_section_title {
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 0.9 em;
}
.toolbar_title {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 1.1em;
}
.toolbar_selector {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 0.9 em;
}
.v-list-item .v-list-item__title {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 0.85em;
}
.scrollable-container {
  overflow-x: auto;
  display: flex;
}
.chart_container {
  overflow: hidden;
}
</style>
