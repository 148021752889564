<template>
    <v-row class="ma-0 pa-0" justify="space-around">
        <!-- <v-col class="ma-0 pa-0" cols="auto">
            <span class="chart_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col> -->
        <v-col cols="12" >
            <GChart
                type="AreaChart"
                :options="options"
                :data="data"></GChart>
        </v-col>
    </v-row>
</template>

<script>
    import { GChart } from "vue-google-charts/legacy";
    export default {
        name: 'PredictionRadarChartOverview',
        props: [
            'statistic_title_name',
            'value',
            'link'
        ],
        components: {
            GChart
        },
        data: () => ({
            statistic_value: '-',
            statistic_name: 'Statistic',
            data: [
                ['Threat','Quantity'],
                ['Threat 1', 50],
                ['Threat 2', 40],
                ['Threat 3', 60],
                ['Threat 4', 55],
                ['Threat 5', 60],
            ],
            options: {
                "legend": { "position": "none" },
                "chartArea": {"margin":"auto auto", "top": "4%", "bottom": "10%", "left": "1%", "right": "1%"},
                "colors": [
                    "#FF6F61", "#FFD166", "#06D6A0", "#118AB2", "#FF7F0E", "#8338EC", "#FF5E78", "#64C4ED", "#E5989B", "#D8A7B1",
                    "#72DDF7", "#426E86", "#C94C4C", "#FAE03C", "#74C69D", "#5975F0", "#FF5733", "#01BAEF", "#AB87FF", "#FFC312",
                    "#B33771", "#F79F1F", "#50A684", "#7FB800", "#5F0F40", "#2A6FB6", "#F4A896", "#5E2B8A", "#5C946E", "#FFADAD",
                    "#C8F7C5", "#FFD700", "#00818A", "#D5F4E6", "#2E294E", "#BA3D49", "#135058", "#FDFFD9", "#A3D2CA", "#C9B1BD",
                    "#FF6F61", "#FFD166", "#06D6A0", "#118AB2", "#FF7F0E", "#8338EC", "#FF5E78", "#64C4ED", "#E5989B", "#D8A7B1",
                    "#72DDF7", "#426E86", "#C94C4C", "#FAE03C", "#74C69D", "#5975F0", "#FF5733", "#01BAEF", "#AB87FF", "#FFC312",
                    "#B33771", "#F79F1F", "#50A684", "#7FB800", "#5F0F40", "#2A6FB6", "#F4A896", "#5E2B8A", "#5C946E", "#FFADAD",
                    "#C8F7C5", "#FFD700", "#00818A", "#D5F4E6", "#2E294E", "#BA3D49", "#135058", "#FDFFD9", "#A3D2CA", "#C9B1BD"
                ],
                "fontName": "Poppins",
                "title": {
                    "position": "center"
                },
                "tooltip": {
                    "isHtml": true
                },
                polar: true,
                series: { 0: { lineWidth: 2 } }
            }
        }),
        watch: {
            value: function(){
                if(this.value != null){
                    this.data = this.value;
                    let series_over = {}
                    if(this.data.length > 0){
                        let total_opts = (this.data[0].length - 1);
                        let num_opts = total_opts / 2;
                        console.log(total_opts)
                        console.log(num_opts)
                        for(let i = 0; i < total_opts; i ++ ){
                            if(i < num_opts){
                                series_over[i] = {'targetAxisIndex': 0};
                            }
                            else{
                                series_over[i] = {'targetAxisIndex': 1};
                            }
                        }
                        console.log(series_over)
                        this.options['series'] = series_over;
                    }
                }
            }
        },
        mounted(){
            if(this.value != null){
                this.data = this.value;
                let series_over = {}
                if(this.data.length > 0){
                    let total_opts = (this.data[0].length - 1);
                    let num_opts = total_opts / 2;
                    console.log(total_opts)
                    console.log(num_opts)
                    for(let i = 0; i < total_opts; i ++ ){
                        if(i < num_opts){
                            series_over[i] = {'targetAxisIndex': 0};
                        }
                        else{
                            series_over[i] = {'targetAxisIndex': 1};
                        }
                    }
                    console.log(series_over)
                    this.options['series'] = series_over;
                }
            }
            else{
                this.data = this.toPolarData(this.data);
            }
        },
        methods: {
            toPolarData(data) {
                const categories = data.slice(1); // remove header row
                const maxValue = 100; // assuming max value is 100 for scaling
                const angleIncrement = (2 * Math.PI) / categories.length;
                
                const polarData = [['X', 'Y', 'pentagon']];

                let start_angle = Math.PI / 2;

                categories.forEach((point, index) => {
                    const angle = start_angle + index * angleIncrement;
                    const radius = point[1] / maxValue; // scale radius to fit
                    const x = radius * Math.cos(angle);
                    const y = radius * Math.sin(angle);
                    polarData.push([x, y, null]);
                });
                polarData.push(polarData[polarData.length-5])

                let radius = 1;
                for (let i = 0; i < 5; i++) {
                    const angle =  start_angle + i * angleIncrement;
                    const x = radius * Math.cos(angle);
                    const y = radius * Math.sin(angle);
                    polarData.push([x, null, y]);
                }
                polarData.push(polarData[polarData.length-5]);
                // const pentagonPoints = this.generatePentagonPoints(1);
                
                return polarData;
            },
            generatePentagonPoints(radius) {
                const points = [];
                const angleIncrement = (2 * Math.PI) / 5; // 5 points for pentagon
                
                for (let i = 0; i < 5; i++) {
                    const angle = i * angleIncrement;
                    const x = radius * Math.cos(angle);
                    const y = radius * Math.sin(angle);
                    points.push([x, null, y]);
                }
                
                // Close the pentagon by repeating the first point
                points.push(points[0]);
                
                return points;
            },
            open_further_details: function(){
                if(this.link){
                    this.$router.replace(this.link);
                }
            }
        }
    }
</script>

<style scoped>
    .chart_title{
        font-family: "Poppins" !important;
        font-weight: 600;
        font-size: 12pt !important;
        line-height: 40px;

        display: block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>