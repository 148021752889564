<template>
    <v-row class="ma-0 pa-0" style="">
        <v-col cols="12">
            <v-menu
                ref="date_range_picker"
                v-model="date_range_picker"
                :close-on-content-click="false"
                :return-value.sync="date_range"
                transition="scale-transition"
                offset-y
                left
                min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                class="toolbar_selector"
                v-model="dateRangeText"
                label="Date Range"
                prepend-icon="mdi-calendar-range"
                readonly
                v-bind="attrs"
                v-on="on"
                ></v-text-field>
            </template>
                <v-row class="ma-0 pa-0" style="background-color: white; width: 450px;">
                    <v-col cols="4" class="ma-0 pa-3"> 
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0 py-1">
                                <v-btn outlined class="toolbar_date_btn ma-0 pa-0" :class="{ 'v-btn--active': isYTD }" @click="setYTD">
                                    YTD
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0 py-1">
                                <v-btn outlined class="toolbar_date_btn ma-0 pa-0" :class="{ 'v-btn--active': isCurrentQuarter }" @click="setCurrentQuarter">
                                    Cur. Qtr.
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0 py-1">
                                <v-btn outlined class="toolbar_date_btn ma-0 pa-0" :class="{ 'v-btn--active': isCurrentHalf }" @click="setCurrentHalf">
                                    Cur. Half
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0 py-1">
                                <v-btn outlined class="toolbar_date_btn ma-0 pa-0" :class="{ 'v-btn--active': isLastQuarter }" @click="setLastQuarter">
                                    Last Qtr.
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0 py-1">
                                <v-btn outlined class="toolbar_date_btn ma-0 pa-0" :class="{ 'v-btn--active': isLastHalf }" @click="setLastHalf">
                                    Last Half
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="ma-0 pa-0 py-1">
                                <v-btn outlined class="toolbar_date_btn ma-0 pa-0" :class="{ 'v-btn--active': isLastYear }" @click="setLastYear">
                                    Last Year
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="8" class="ma-0 pa-0" style="min-width: 300px">
                        <v-date-picker
                            v-model="date_range"
                            :min="min_start_date"
                            :max="max_end_date"
                            no-title
                            scrollable
                            range
                            color="black"
                        >
                            <v-row class="ma-0 pa-0">
                                <v-col cols="6">
                                    <v-btn
                                        class="toolbar_date_btn"
                                        outlined
                                        color="primary"
                                        @click="date_range_picker = false"
                                    >
                                        Cancel
                                    </v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn
                                        class="toolbar_date_btn"
                                        outlined
                                        color="primary"
                                        @click="$refs.date_range_picker.save(date_range); commit_date_range()"
                                    >
                                        OK
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-date-picker>
                    </v-col>
                </v-row>
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'DateRange',
    props: [
        'start_date',
        'end_date',
        'min_start_date',
        'max_end_date'
    ],
    mounted(){
    },
    computed: {
        dateRangeText () {
            return this.date_range.join(' ~ ')
        },
        isYTD() {
            const now = new Date(this.max_end_date);
            const currentYear = now.getFullYear();
            const startDate = new Date(currentYear, 0, 1);
            return this.compareDates([startDate, now]);
        },
        isCurrentQuarter() {
            const now = new Date(this.max_end_date);
            const currentMonth = now.getMonth();
            const currentYear = now.getFullYear();
            let startMonth = Math.floor(currentMonth / 3) * 3;
            const startDate = new Date(currentYear, startMonth, 1);
            const endDate = new Date(currentYear, startMonth + 3, 0);
            return this.compareDates([startDate, endDate]);
        },
        isCurrentHalf() {
            const now = new Date(this.max_end_date);
            const currentYear = now.getFullYear();
            const startMonth = now.getMonth() < 6 ? 0 : 6;
            const startDate = new Date(currentYear, startMonth, 1);
            const endDate = new Date(currentYear, startMonth + 6, 0);
            return this.compareDates([startDate, endDate]);
        },
        isLastQuarter() {
            const now = new Date(this.max_end_date);
            const currentMonth = now.getMonth();
            const currentYear = now.getFullYear();
            let startMonth, startYear;
            if (currentMonth < 3) {
                startMonth = 9;
                startYear = currentYear - 1;
            } else if (currentMonth < 6) {
                startMonth = 0;
                startYear = currentYear;
            } else if (currentMonth < 9) {
                startMonth = 3;
                startYear = currentYear;
            } else {
                startMonth = 6;
                startYear = currentYear;
            }
            const startDate = new Date(startYear, startMonth, 1);
            const endDate = new Date(startYear, startMonth + 3, 0);
            return this.compareDates([startDate, endDate]);
        },
        isLastHalf() {
            const now = new Date(this.max_end_date);
            const currentMonth = now.getMonth();
            const currentYear = now.getFullYear();
            let startMonth, startYear;
            if (currentMonth < 6) {
                startMonth = 6;
                startYear = currentYear - 1;
            } else {
                startMonth = 0;
                startYear = currentYear;
            }
            const startDate = new Date(startYear, startMonth, 1);
            const endDate = new Date(startYear, startMonth + 6, 0);
            return this.compareDates([startDate, endDate]);
        },
        isLastYear() {
            const now = new Date(this.max_end_date);
            const lastYear = now.getFullYear() - 1;
            const startDate = new Date(lastYear, 0, 1);
            const endDate = new Date(lastYear, 11, 31);
            return this.compareDates([startDate, endDate]);
        }
    },
    data: () => ({
        date_range_picker: false,
        s_date: null,
        e_date: null,
        date_range: []
    }),
    methods: {
        commit_date_range: function(){
            this.$emit('date_update',this.date_range);
        },
        compareDates(range) {
            const [start, end] = range;
            const [selectedStart, selectedEnd] = this.date_range;
            return (
                start.toISOString().substr(0, 10) === selectedStart &&
                end.toISOString().substr(0, 10) === selectedEnd
            );
        },
        setLastQuarter() {
            const now = new Date(this.max_end_date);
            const currentMonth = now.getMonth();
            const currentYear = now.getFullYear();
            let startMonth, startYear;

            if (currentMonth < 3) {
                startMonth = 9;
                startYear = currentYear - 1;
            } else if (currentMonth < 6) {
                startMonth = 0;
                startYear = currentYear;
            } else if (currentMonth < 9) {
                startMonth = 3;
                startYear = currentYear;
            } else {
                startMonth = 6;
                startYear = currentYear;
            }

            const startDate = new Date(startYear, startMonth, 1);
            const endDate = new Date(startYear, startMonth + 3, 0);

            this.date_range = [
                this.boundDate(startDate, this.min_start_date, this.max_end_date),
                this.boundDate(endDate, this.min_start_date, this.max_end_date),
            ];
        },
        setLastHalf() {
            const now = new Date(this.max_end_date);
            const currentMonth = now.getMonth();
            const currentYear = now.getFullYear();
            let startMonth, startYear;

            if (currentMonth < 6) {
                startMonth = 6;
                startYear = currentYear - 1;
            } else {
                startMonth = 0;
                startYear = currentYear;
            }

            const startDate = new Date(startYear, startMonth, 1);
            const endDate = new Date(startYear, startMonth + 6, 0);

            this.date_range = [
                this.boundDate(startDate, this.min_start_date, this.max_end_date),
                this.boundDate(endDate, this.min_start_date, this.max_end_date),
            ];
        },
        setLastYear() {
            const now = new Date(this.max_end_date);
            const lastYear = now.getFullYear() - 1;
            const startDate = new Date(lastYear, 0, 1);
            const endDate = new Date(lastYear, 11, 31);

            this.date_range = [
                this.boundDate(startDate, this.min_start_date, this.max_end_date),
                this.boundDate(endDate, this.min_start_date, this.max_end_date),
            ];
        },
        setYTD() {
            const now = new Date(this.max_end_date);
            const currentYear = now.getFullYear();
            const startDate = new Date(currentYear, 0, 1);

            this.date_range = [
                this.boundDate(startDate, this.min_start_date, this.max_end_date),
                this.boundDate(now, this.min_start_date, this.max_end_date),
            ];
        },
        setCurrentQuarter() {
            const now = new Date(this.max_end_date);
            const currentMonth = now.getMonth();
            const currentYear = now.getFullYear();
            let startMonth;

            if (currentMonth < 3) {
                startMonth = 0;
            } else if (currentMonth < 6) {
                startMonth = 3;
            } else if (currentMonth < 9) {
                startMonth = 6;
            } else {
                startMonth = 9;
            }

            const startDate = new Date(currentYear, startMonth, 1);
            const endDate = new Date(currentYear, startMonth + 3, 0);

            this.date_range = [
                this.boundDate(startDate, this.min_start_date, this.max_end_date),
                this.boundDate(endDate, this.min_start_date, this.max_end_date),
            ];
        },
        setCurrentHalf() {
            const now = new Date(this.max_end_date);
            const currentMonth = now.getMonth();
            const currentYear = now.getFullYear();
            let startMonth;

            if (currentMonth < 6) {
                startMonth = 0;
            } else {
                startMonth = 6;
            }

            const startDate = new Date(currentYear, startMonth, 1);
            const endDate = new Date(currentYear, startMonth + 6, 0);

            this.date_range = [
                this.boundDate(startDate, this.min_start_date, this.max_end_date),
                this.boundDate(endDate, this.min_start_date, this.max_end_date),
            ];
        },
        boundDate(date, minDate, maxDate) {
            const min = new Date(minDate);
            const max = new Date(maxDate);
            return date < min ? min.toISOString().substr(0, 10) : date > max ? max.toISOString().substr(0, 10) : date.toISOString().substr(0, 10);
        }
    },
    watch: {
        start_date: {
            immediate: true,
            handler: function () {
                this.s_date = this.start_date;
                this.date_range = [this.start_date, this.end_date];
            },
        },
        end_date: {
            immediate: true,
            handler: function () {
                this.e_date = this.end_date;
                this.date_range = [this.start_date, this.end_date];
            },
        }
    },
}
</script>

<style scoped>
    .toolbar_date_btn{
        font-family: 'Poppins';
        font-weight: 600;
        width: 100%;
    }
    .v-btn--active {
        border-color: #000000;
        background-color: #000000;
        color: white !important;
    }
</style>